.LoadingIndicator.loading {
    font-size: 32px;
    line-height: inherit;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 4em;
    margin-left: auto;
    margin-right: auto;
  
    .loading-indicator {
      display: inline-block;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background-color: rgba(#000, 0.5);
      animation-name: loading-dots-animation;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      opacity: 0.2;
    }
  
    .loading-indicator1 {
      animation-delay: 0s;
    }
    .loading-indicator2 {
      animation-delay: 0.2s;
    }
    .loading-indicator3 {
      animation-delay: 0.4s;
    }
    @keyframes loading-dots-animation {
      0% { opacity: 0.2; }
      50% { opacity: 0.7; }
      100% { opacity: 0.2; }
    }
  }
  