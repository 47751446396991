.PageTitle-container {
  .breadcrumb {
    padding: 0;
    line-height: 38px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 38px;
    margin: 0;
  }
}