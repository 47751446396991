@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";

@import './style/_variables';

@import "~bootstrap/scss/bootstrap";

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    box-shadow: 0 0 0.5rem 0 rgba(darken($value, 30%), 0.5);
  }
}

.App {
  width: 100%;
  height: 100vh;

  #sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: $leftbar-width;
    z-index: 999;
    background: $bg-leftbar;
    color: #fff;
    transition: all 0.3s;

    .nav-align-bottom {
      position: absolute;
      bottom: 20px; // avoid browser status bar
      left: 0;
      right: 0;
    }

    a {
      transition: opacity 0.3s;
      color: #fff;
      opacity: 0.6;
      &.disabled {
        opacity: 1;
      }
      &, &:hover { 
        h3 {
          text-transform: uppercase;
          text-align: center;
          color: #fff;
          font-weight: 600;
        }
      }
      &:hover {
        opacity: 1;
      }
      &.active {
        opacity: 1;
      }
      &.brand-logo {
        opacity: 1;
      }
    }
  }

  main {
    margin-left: $leftbar-width;
    height: 100%;
    &.not-logged-in {
      margin-left: 0;
    }
  }
}

.LoginScreen {
  .card-header {
    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }
  }
}

.breadcrumb-item {
  font-size: $h4-font-size;
  font-weight: $headings-font-weight;
}

.page-link {
  min-width: 3rem;
}

.pagination {
  justify-content: center;
}

.bootstrap-react-select__control {
  &.bootstrap-react-select__control--is-focused {
    border-color: $primary;
    box-shadow: 0 0 0 1px $primary;
    &:hover {
      border-color: $primary;
    }
  }
  &:hover {
    border-color: $primary;
  }
}

// Add this to your <Select>:
// className="bootstrap-react-select"
// classNamePrefix="bootstrap-react-select"
.bootstrap-react-select__control {
  &.bootstrap-react-select__control--is-focused {
    border-color: $primary;
    box-shadow: 0 0 0 1px $primary;
    &:hover {
      border-color: $primary;
    }
  }
  &:hover {
    border-color: $primary;
  }
}

// Add this to your <Select>:
// className="bootstrap-react-select"
// classNamePrefix="bootstrap-react-select"
.bootstrap-react-select__menu {
  .bootstrap-react-select__menu-list {
    .bootstrap-react-select__option {
      color: $input-color;
      &.bootstrap-react-select__option--is-selected {
        background-color: $primary;
        color: color-yiq($primary);
        &.bootstrap-react-select__option--is-focused {
          background-color: $primary;
          &:hover,&:active {
            background-color: darken($primary, 7.5%);
          }  
        }
        &:hover,&:active {
          background-color: $primary;
        }
      }

      &.bootstrap-react-select__option--is-focused {
        background-color: $gray-100;
        &:hover,&:active {
          background-color: $gray-100;
        }  
      }
      &:hover,&:active {
        background-color: $gray-100;
      }
    }
  }
}
